import React from "react";

import TypeFormBtn from "../form/typeform";

const HeroSection = () => {
  return (
    <section className="hero-part-section" id="overview">
      <div className="you-tube-h1-box">
        <div className="text-action-btn-box">
          <h1 className="hero-title">
            Code-Free
            <br />
            App Creation
          </h1>
          <p className="hero-desc">
            On our way to unveil a groundbreaking platform
            <br />
            for app creation, pioneering the future of innovation.
          </p>
          <p className="hero-desc-mobile">
            On our way to unveil a groundbreaking platform for app creation,
            pioneering the future of innovation.
          </p>
          <TypeFormBtn />
        </div>
        <div className="you-tube-embed">
          <iframe
            width="385"
            height="216"
            src="https://www.youtube.com/embed/NjyPRM3LjGg?si=6En1uK6JbuwX8xJW"
            title="PAP in a minute"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
            referrerPolicy="strict-origin-when-cross-origin"
            className="you-tube-vide-iframe"
          ></iframe>
        </div>
      </div>
      <div className="content-box">
        <div className="video-place">
          <video
            src="https://stpapplatformeastau01.blob.core.windows.net/website/hero/hero-video-desktop.webm"
            autoPlay
            loop
            type="video/webm"
            muted
            className="video-hero desktop-version"
            width={936}
            height={477}
            playsInline
            poster="https://stpapplatformeastau01.blob.core.windows.net/website/hero/hero-video-desktop-poster.png"
          ></video>
          <img
            src="https://stpapplatformeastau01.blob.core.windows.net/website/hero/Mobile_Frame.png"
            alt="mobile frame"
            className="mobile-version mobile-frame"
            width={300}
          />
          <span className="behind-video mobile-version"></span>
          <video
            src="https://stpapplatformeastau01.blob.core.windows.net/website/hero/mobile_inside_video.mp4"
            autoPlay
            loop
            type="video/mp4"
            muted
            className="video-hero mobile-version"
            width={277}
            height={552}
            playsInline
          ></video>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
